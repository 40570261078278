import Axios, { AxiosRequestConfig } from "axios";
// eslint-disable-next-line no-undef
export const AXIOS_INSTANCE = Axios.create({
  // eslint-disable-next-line no-undef
  baseURL: process.env.REACT_APP_API_GATEWAY_ENDPOINT,
});

const ID_TOKEN_KEY = "idToken";

export const useCustomClient = <T>(): ((
  config: AxiosRequestConfig
) => Promise<T>) => {
  const token = localStorage.getItem(ID_TOKEN_KEY);

  return (config: AxiosRequestConfig) => {
    const source = Axios.CancelToken.source();
    const promise = AXIOS_INSTANCE({
      ...config,
      cancelToken: source.token,
      headers: { Authorization: `Bearer ${token}` },
    }).then(({ data }) => data);

    // @ts-ignore
    promise.cancel = () => {
      source.cancel("Query was cancelled by React Query");
    };

    return promise;
  };
};

export default useCustomClient;

export type ErrorType<ErrorData> = ErrorData;

export type BodyType<BodyData> = BodyData & { headers?: any };
