import { useEffect, useCallback } from "react";

import { useNavigate } from "react-router-dom";

import {
  LoginBodiesAuthFlow,
  useLoginHook,
  useVerifyHook,
} from "../api-client";

export const Session = () => {
  const navigate = useNavigate();
  const observeAuth = useCallback(async () => {
    const idToken = localStorage.getItem("idToken");

    if (!idToken) {
      localStorage.clear();
      return;
    }
    try {
      const verify = useVerifyHook();
      await verify();
    } catch (e: any) {
      try {
        const login = useLoginHook();

        // refreshTokenによってidTokenを最新化する
        const result = await login({
          refreshToken: localStorage.getItem("refreshToken")!,
          authFlow: LoginBodiesAuthFlow.REFRESH_TOKEN_AUTH,
        });
        if (
          (result?.data?.idToken && result?.data?.accessToken,
          result?.data?.refreshToken)
        ) {
          /*setToken(
            result?.data.idToken,
            result?.data.accessToken,
            result?.data.refreshToken
          );*/
          localStorage.idToken = result?.data.idToken;
          localStorage.accessToken = result?.data.accessToken;
          localStorage.refreshToken = result?.data.refreshToken;
        }
      } catch (e) {
        localStorage.clear();
        navigate("/login");
      }
      return;
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    observeAuth();
    // eslint-disable-next-line
  }, [observeAuth]);

  return <>{null}</>;
};
