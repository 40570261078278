import { lazy, Suspense } from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Progress } from "../components/atoms/Progress";
import { RouteAuthGuard } from "../provider/routerAuthGuard";
import { Session } from "../util/Session";

const AdminPage = lazy(() => import("./AdminPage"));
const LoginPage = lazy(() => import("./LoginPage"));
const ClinicsPage = lazy(() => import("./ClinicsPage"));
const NotFoundPage = lazy(() => import("./NotFoundPage"));
const ForbiddenPage = lazy(() => import("./ForbiddenPage"));

const pages = [
  {
    path: "/",
    component:
      <>
        <Session />
        <AdminPage />
      </>,
  },
  {
    path: "/login",
    component:
      <>
        <Session />
        <LoginPage />
      </>,
  },
  {
    path: "/clinics",
    component:
      <>
        <Session />
        <ClinicsPage />
      </>,
  },
];
export const Router = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Progress />}>
        <ToastContainer
          style={{ minWidth: "500px" }}
          position="top-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Routes>
          {pages.map((item, index) => (
            <Route
              key={index}
              path={item.path}
              element={<RouteAuthGuard component={item.component} />}
            />
          ))}
          <Route path="/forbidden" element={<RouteAuthGuard component={<ForbiddenPage />} />} />
          <Route path="*" element={<RouteAuthGuard component={<NotFoundPage />} />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};
