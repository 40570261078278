import React from "react";

import { createTheme, ThemeProvider } from "@mui/material";
import type {} from "@mui/x-data-grid/themeAugmentation";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    bold16: React.CSSProperties;
    bold15: React.CSSProperties;
    bold14: React.CSSProperties;
    bold13: React.CSSProperties;
    bold12: React.CSSProperties;
    regular16: React.CSSProperties;
    regular15: React.CSSProperties;
    regular14: React.CSSProperties;
    regular13: React.CSSProperties;
    regular12: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    bold16: React.CSSProperties;
    bold15: React.CSSProperties;
    bold14: React.CSSProperties;
    bold13: React.CSSProperties;
    bold12: React.CSSProperties;
    regular16: React.CSSProperties;
    regular15: React.CSSProperties;
    regular14: React.CSSProperties;
    regular13: React.CSSProperties;
    regular12: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    bold16: true;
    bold15: true;
    bold14: true;
    bold13: true;
    bold12: true;
    regular16: true;
    regular15: true;
    regular14: true;
    regular13: true;
    regular12: true;
  }
}

export const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#2448BA",
    },
    secondary: {
      main: "#355CD7",
    },
    grey: {
      300: "#F7F8FB",
      400: "#DDDDE0",
      500: "#898989",
    },
    background: {
      default: "#FFFFFF",
      paper: "#F5F7FD",
    },
    text: {
      primary: "#000000",
      secondary: "#FFFFFF",
    },
  },
  typography: {
    bold16: {
      fontSize: 16,
      fontWeight: 700,
      lineHeight: "24px",
    },
    bold15: {
      fontSize: 15,
      fontWeight: 700,
      lineHeight: "22.5px",
    },
    bold14: {
      fontSize: 14,
      fontWeight: 700,
      lineHeight: "19.6px",
    },
    bold13: {
      fontSize: 13,
      fontWeight: 700,
      lineHeight: "18.2px",
    },
    bold12: {
      fontSize: 12,
      fontWeight: 700,
      lineHeight: "16.8px",
    },
    regular16: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: "24px",
    },
    regular15: {
      fontSize: 15,
      fontWeight: 400,
      lineHeight: "22.5px",
    },
    regular14: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: "19.6px",
    },
    regular13: {
      fontSize: 13,
      fontWeight: 400,
      lineHeight: "140%",
    },
    regular12: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "16.8px",
    },
  },
  // MuiButtonBase-root-MuiCheckbox-root
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeaders: () => ({
          ...{
            color: '#949597',
            borderTop: '1px solid #E9E9E9'
          },
        }),
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: "h1",
          h2: "h2",
          bold16: "p",
          bold15: "p",
          bold14: "p",
          bold13: "p",
          bold12: "p",
          regular16: "p",
          regular15: "p",
          regular14: "p",
          regular13: "p",
          regular12: "p",
        },
      },
      styleOverrides: {
        h1: {
          fontSize: 28,
          fontWeight: 700,
          lineHeight: "150%",
        },
        h2: {
          fontSize: 24,
          fontWeight: 700,
          lineHeight: "32px",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: ({ _, theme }) => ({
          ...{
            color: theme.palette.text.primary,
          },
        }),
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: ({ _, theme }) => ({
          ...{
            color: theme.palette.grey[400],
          },
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: 16,
          fontWeight: 600,
          padding: "12px 50px",
        },
        contained: {
          backgroundColor: "#355CD7",
        },
        text: {
          color: "#555555",
        },
      },
    },
  },
});

type ThemeProviderWrapProps = React.PropsWithChildren<{}>;

export const ThemeProviderWrap: React.FC<ThemeProviderWrapProps> = ({
  children,
}) => <ThemeProvider theme={theme}>{children}</ThemeProvider>;
