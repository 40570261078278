import { useEffect, useState, useCallback } from "react";

type UseLocalStorageKey = "idToken" | "accessToken" | "refreshToken" | "id" | "email";

export const useLocalStorage = (
  key: UseLocalStorageKey
): [string | null, (value: string) => void, () => void] => {
  const [, setValue] = useState<string | null>(localStorage.getItem(key));

  useEffect(() => {
    const handleStorageEvent = (event: StorageEvent) => {
      if (event.key === key) {
        setValue(event.newValue);
      }
    };

    window.addEventListener("storage", handleStorageEvent);

    return () => {
      window.removeEventListener("storage", handleStorageEvent);
    };
  }, [key]);

  const updateValue = useCallback(
    (newValue: string) => {
      localStorage.setItem(key, newValue);
      setValue(newValue);
    },
    [key]
  );

  const deleteValue = useCallback(() => {
    localStorage.removeItem(key);
    setValue(null);
  }, [key]);
  return [localStorage.getItem(key), updateValue, deleteValue];
};
