/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * inojin-stg-api
 * OpenAPI spec version: 1.0.0
 */
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  GetAdminsResponseResponse,
  BadRequestResponse,
  InternalServerErrorResponse,
  DataOKResponseResponse,
  PatchAdminAccountFinishedFirstLoginFlagBodies,
  SuccessResponseResponse,
  CreateAdminUserBodies,
  PatchAdminUserBodies,
  DeleteAdminUserParams,
  CreateClinicBodies,
  PatchAdminClinicBodies,
  DeleteAdminClinicParams
} from '.././model'
import { useCustomClient } from '../../apiClientConfig/customClient';
import type { ErrorType, BodyType } from '../../apiClientConfig/customClient';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


/**
 * # 仕様
- 管理者のリストを返す

 * @summary 管理者一覧取得API
 */
export const useGetAdminsHook = () => {
        const getAdmins = useCustomClient<GetAdminsResponseResponse>();

        return (
    
 signal?: AbortSignal
) => {
        return getAdmins(
          {url: `/v1/admins`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetAdminsQueryKey = () => [`/v1/admins`] as const;
  

    
export const useGetAdminsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetAdminsHook>>>, TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAdminsHook>>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAdminsHook>>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAdminsQueryKey();

  const getAdmins =  useGetAdminsHook();
  
    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAdminsHook>>>> = ({ signal }) => getAdmins(signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetAdminsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAdminsHook>>>>
export type GetAdminsQueryError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

/**
 * @summary 管理者一覧取得API
 */
export const useGetAdmins = <TData = Awaited<ReturnType<ReturnType<typeof useGetAdminsHook>>>, TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAdminsHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetAdminsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * # 仕様
- Adminユーザの初回ログインフラグを1にする
 * @summary Adminユーザの初回ログイン済みフラグ
 */
export const usePatchAdminAccountFinishedFirstLoginFlagHook = () => {
        const patchAdminAccountFinishedFirstLoginFlag = useCustomClient<DataOKResponseResponse>();

        return (
    patchAdminAccountFinishedFirstLoginFlagBodies: BodyType<PatchAdminAccountFinishedFirstLoginFlagBodies>,
 ) => {
        return patchAdminAccountFinishedFirstLoginFlag(
          {url: `/v1/admins/patchAdminAccountFinishedFirstLoginFlag`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchAdminAccountFinishedFirstLoginFlagBodies
    },
          );
        }
      }
    


export const usePatchAdminAccountFinishedFirstLoginFlagMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchAdminAccountFinishedFirstLoginFlagHook>>>, TError,{data: BodyType<PatchAdminAccountFinishedFirstLoginFlagBodies>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchAdminAccountFinishedFirstLoginFlagHook>>>, TError,{data: BodyType<PatchAdminAccountFinishedFirstLoginFlagBodies>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const patchAdminAccountFinishedFirstLoginFlag =  usePatchAdminAccountFinishedFirstLoginFlagHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePatchAdminAccountFinishedFirstLoginFlagHook>>>, {data: BodyType<PatchAdminAccountFinishedFirstLoginFlagBodies>}> = (props) => {
          const {data} = props ?? {};

          return  patchAdminAccountFinishedFirstLoginFlag(data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PatchAdminAccountFinishedFirstLoginFlagMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePatchAdminAccountFinishedFirstLoginFlagHook>>>>
    export type PatchAdminAccountFinishedFirstLoginFlagMutationBody = BodyType<PatchAdminAccountFinishedFirstLoginFlagBodies>
    export type PatchAdminAccountFinishedFirstLoginFlagMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary Adminユーザの初回ログイン済みフラグ
 */
export const usePatchAdminAccountFinishedFirstLoginFlag = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchAdminAccountFinishedFirstLoginFlagHook>>>, TError,{data: BodyType<PatchAdminAccountFinishedFirstLoginFlagBodies>}, TContext>, }
) => {
    
      const mutationOptions = usePatchAdminAccountFinishedFirstLoginFlagMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * # 仕様
- emailと代表者名を受け取りパスワードを生成しアカウントを作成する。

# 画面
- https://www.figma.com/file/JuCk6DDPjoaDO4S4mdUm8A/Innojin?type=design&node-id=540-27622&t=rkKUZpJhTwybuV2q-4
 * @summary 管理者作成API
 */
export const useCreateAdminUserHook = () => {
        const createAdminUser = useCustomClient<SuccessResponseResponse>();

        return (
    createAdminUserBodies: BodyType<CreateAdminUserBodies>,
 ) => {
        return createAdminUser(
          {url: `/v1/createAdminUser`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createAdminUserBodies
    },
          );
        }
      }
    


export const useCreateAdminUserMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateAdminUserHook>>>, TError,{data: BodyType<CreateAdminUserBodies>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateAdminUserHook>>>, TError,{data: BodyType<CreateAdminUserBodies>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const createAdminUser =  useCreateAdminUserHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCreateAdminUserHook>>>, {data: BodyType<CreateAdminUserBodies>}> = (props) => {
          const {data} = props ?? {};

          return  createAdminUser(data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateAdminUserMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateAdminUserHook>>>>
    export type CreateAdminUserMutationBody = BodyType<CreateAdminUserBodies>
    export type CreateAdminUserMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary 管理者作成API
 */
export const useCreateAdminUser = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateAdminUserHook>>>, TError,{data: BodyType<CreateAdminUserBodies>}, TContext>, }
) => {
    
      const mutationOptions = useCreateAdminUserMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * # 仕様
- emailと代表者名を受け取りアカウントを更新する。

# 画面
- https://www.figma.com/file/JuCk6DDPjoaDO4S4mdUm8A/Innojin?type=design&node-id=540-27622&t=rkKUZpJhTwybuV2q-4
 * @summary 管理者更新API
 */
export const usePatchAdminUserHook = () => {
        const patchAdminUser = useCustomClient<SuccessResponseResponse>();

        return (
    id: string,
    patchAdminUserBodies: BodyType<PatchAdminUserBodies>,
 ) => {
        return patchAdminUser(
          {url: `/v1/adminUser/${id}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchAdminUserBodies
    },
          );
        }
      }
    


export const usePatchAdminUserMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchAdminUserHook>>>, TError,{id: string;data: BodyType<PatchAdminUserBodies>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchAdminUserHook>>>, TError,{id: string;data: BodyType<PatchAdminUserBodies>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const patchAdminUser =  usePatchAdminUserHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePatchAdminUserHook>>>, {id: string;data: BodyType<PatchAdminUserBodies>}> = (props) => {
          const {id,data} = props ?? {};

          return  patchAdminUser(id,data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PatchAdminUserMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePatchAdminUserHook>>>>
    export type PatchAdminUserMutationBody = BodyType<PatchAdminUserBodies>
    export type PatchAdminUserMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary 管理者更新API
 */
export const usePatchAdminUser = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchAdminUserHook>>>, TError,{id: string;data: BodyType<PatchAdminUserBodies>}, TContext>, }
) => {
    
      const mutationOptions = usePatchAdminUserMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * # 仕様
- IDを受け取りアカウントを削除する。

# 画面
- https://www.figma.com/file/JuCk6DDPjoaDO4S4mdUm8A/Innojin?type=design&node-id=540-27622&t=rkKUZpJhTwybuV2q-4
 * @summary 管理者削除API
 */
export const useDeleteAdminUserHook = () => {
        const deleteAdminUser = useCustomClient<SuccessResponseResponse>();

        return (
    params?: DeleteAdminUserParams,
 ) => {
        return deleteAdminUser(
          {url: `/v1/adminUser`, method: 'delete',
        params
    },
          );
        }
      }
    


export const useDeleteAdminUserMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteAdminUserHook>>>, TError,{params?: DeleteAdminUserParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteAdminUserHook>>>, TError,{params?: DeleteAdminUserParams}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const deleteAdminUser =  useDeleteAdminUserHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteAdminUserHook>>>, {params?: DeleteAdminUserParams}> = (props) => {
          const {params} = props ?? {};

          return  deleteAdminUser(params,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteAdminUserMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteAdminUserHook>>>>
    
    export type DeleteAdminUserMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary 管理者削除API
 */
export const useDeleteAdminUser = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteAdminUserHook>>>, TError,{params?: DeleteAdminUserParams}, TContext>, }
) => {
    
      const mutationOptions = useDeleteAdminUserMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * # 仕様
- クリニック名とemailとを受け取りパスワードを生成しアカウントを作成する。
- アカウント作成後はリクエストのメールアドレスに対して初回メールアドレスを送付する。

# 画面
- https://www.figma.com/file/JuCk6DDPjoaDO4S4mdUm8A/Innojin?type=design&node-id=540-27594&t=rkKUZpJhTwybuV2q-4
 * @summary クリニック作成API
 */
export const useCreateClinicHook = () => {
        const createClinic = useCustomClient<DataOKResponseResponse>();

        return (
    createClinicBodies: BodyType<CreateClinicBodies>,
 ) => {
        return createClinic(
          {url: `/v1/createClinic`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createClinicBodies
    },
          );
        }
      }
    


export const useCreateClinicMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateClinicHook>>>, TError,{data: BodyType<CreateClinicBodies>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateClinicHook>>>, TError,{data: BodyType<CreateClinicBodies>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const createClinic =  useCreateClinicHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCreateClinicHook>>>, {data: BodyType<CreateClinicBodies>}> = (props) => {
          const {data} = props ?? {};

          return  createClinic(data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateClinicMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateClinicHook>>>>
    export type CreateClinicMutationBody = BodyType<CreateClinicBodies>
    export type CreateClinicMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary クリニック作成API
 */
export const useCreateClinic = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateClinicHook>>>, TError,{data: BodyType<CreateClinicBodies>}, TContext>, }
) => {
    
      const mutationOptions = useCreateClinicMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * # 仕様
- クリニック名とemailとを受け取りアカウントを更新する。

# 画面
- https://www.figma.com/file/JuCk6DDPjoaDO4S4mdUm8A/Innojin?type=design&node-id=540-27594&t=rkKUZpJhTwybuV2q-4
 * @summary クリニック更新API
 */
export const usePatchAdminClinicHook = () => {
        const patchAdminClinic = useCustomClient<DataOKResponseResponse>();

        return (
    id: string,
    patchAdminClinicBodies: BodyType<PatchAdminClinicBodies>,
 ) => {
        return patchAdminClinic(
          {url: `/v1/adminClinic/${id}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchAdminClinicBodies
    },
          );
        }
      }
    


export const usePatchAdminClinicMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchAdminClinicHook>>>, TError,{id: string;data: BodyType<PatchAdminClinicBodies>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchAdminClinicHook>>>, TError,{id: string;data: BodyType<PatchAdminClinicBodies>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const patchAdminClinic =  usePatchAdminClinicHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePatchAdminClinicHook>>>, {id: string;data: BodyType<PatchAdminClinicBodies>}> = (props) => {
          const {id,data} = props ?? {};

          return  patchAdminClinic(id,data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PatchAdminClinicMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePatchAdminClinicHook>>>>
    export type PatchAdminClinicMutationBody = BodyType<PatchAdminClinicBodies>
    export type PatchAdminClinicMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary クリニック更新API
 */
export const usePatchAdminClinic = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchAdminClinicHook>>>, TError,{id: string;data: BodyType<PatchAdminClinicBodies>}, TContext>, }
) => {
    
      const mutationOptions = usePatchAdminClinicMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * # 仕様
- IDとを受け取りアカウントを削除する。

# 画面
- https://www.figma.com/file/JuCk6DDPjoaDO4S4mdUm8A/Innojin?type=design&node-id=540-27594&t=rkKUZpJhTwybuV2q-4
 * @summary クリニック削除API
 */
export const useDeleteAdminClinicHook = () => {
        const deleteAdminClinic = useCustomClient<DataOKResponseResponse>();

        return (
    params?: DeleteAdminClinicParams,
 ) => {
        return deleteAdminClinic(
          {url: `/v1/adminClinic`, method: 'delete',
        params
    },
          );
        }
      }
    


export const useDeleteAdminClinicMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteAdminClinicHook>>>, TError,{params?: DeleteAdminClinicParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteAdminClinicHook>>>, TError,{params?: DeleteAdminClinicParams}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const deleteAdminClinic =  useDeleteAdminClinicHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteAdminClinicHook>>>, {params?: DeleteAdminClinicParams}> = (props) => {
          const {params} = props ?? {};

          return  deleteAdminClinic(params,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteAdminClinicMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteAdminClinicHook>>>>
    
    export type DeleteAdminClinicMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary クリニック削除API
 */
export const useDeleteAdminClinic = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteAdminClinicHook>>>, TError,{params?: DeleteAdminClinicParams}, TContext>, }
) => {
    
      const mutationOptions = useDeleteAdminClinicMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    