import {useEffect} from "react";


import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {AwsRum, AwsRumConfig} from "aws-rum-web";

import {Router} from "./pages/router";
import {AuthContextProvider} from "./provider/authContextProvider";
import {ThemeProviderWrap} from "./provider/themeProvider";

import './App.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {

  useEffect(() => {
    try {
      const config: AwsRumConfig = {
        sessionSampleRate: 1,
        identityPoolId: process.env.REACT_APP_RUM_POOL_ID || "",
        endpoint: "https://dataplane.rum.ap-northeast-1.amazonaws.com",
        telemetries: ["performance", "errors", "http"],
        allowCookies: true,
        enableXRay: false
      };

      const APPLICATION_ID: string = process.env.REACT_APP_RUM_APPLICATION_ID || "";
      const APPLICATION_VERSION: string = '1.0.0';
      const APPLICATION_REGION: string = 'ap-northeast-1';

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const awsRum: AwsRum = new AwsRum(
        APPLICATION_ID,
        APPLICATION_VERSION,
        APPLICATION_REGION,
        config
      );
    } catch (error) {
      console.error("Failed to initialize AWS RUM:", error);
    }
  }, []);

  return (
    <AuthContextProvider>
      <QueryClientProvider client={queryClient}>
        <ThemeProviderWrap>
          <Router/>
        </ThemeProviderWrap>
      </QueryClientProvider>
    </AuthContextProvider>
  );
};

export default App;
